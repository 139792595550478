.auth-layout-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(145deg, #004685, #00a3f6, #00a2f2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-user-container {
  transform: translateY(20px);
  padding: 10px 40px 10px 10px;
  background-color: #84d6ff;
}

.layout {
  &-hover a {
    font-weight: 500;
  }
  &-hover span {
    font-weight: 500;
  }
  &-hover:hover a {
    color: #1a1a1aba !important;
  }
  &-hover:hover span {
    color: #1a1a1aba !important;
  }
  &-container {
    // background-color: #58c6ff !important;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  &__footer {
    background-color: #00AAFF;
    width: 100%;
    margin-top: 100px;
    padding-bottom: 40px;
    &-container {
      max-width: 1050px;
      margin: auto;
    }

    &-contact {
      background-color: #ffffff;
      border-radius: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 3rem;
      margin: -50px auto;
      box-shadow: 0px 0px 10px 0px rgba(31.999999999999986, 58.00000000000004, 107.99999999999996, 0.19);
      &__logo {
        width: 70px;
      }

      &__title {
        font-size: 24px;
        margin-bottom: 5px;
      }

      &__text {
        font-size: 16px;
        color: #54595F;
      }

      &__button {
        font-size: 12px;
        color: #ffffff;
        background-color: #00AAFF;
        border-radius: 20px;
        padding: 8px 20px;
        height: 35px;
        &:hover {
          color: #ffffff;
        }
      }
    }

    &-download {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 70px;
      gap: 20px;
      margin-bottom: 50px;

      &__logo {
        width: 200px;
        height: 60px;
      }
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      margin: auto;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          color: #ffffff;
          span {
            font-weight: 600;
            margin-bottom: 20px;
            display: block;
            text-align: center;
          }
          a {
            cursor: pointer;
            color: #ffffff;
            padding: 10px 15px;
            display: block;
            border-radius: 10px;
            text-align: center;
            &:active {
              background-color: #00a3f6;
            }
            &:hover {
              background-color: #00a3f6;
            }
          }
        }
      }
    }
  }
  &-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #ffffff;
    position: relative;
    &-mobile {
      display: none;
      background-color: #ffffff;
      border-bottom: 1px solid #f7f7f7;
      width: 100%;
      margin-bottom: 30px;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 35px;
      height: 80px;
      & .layout-nav__logo {
        height: 40px;
      }
      &__drop {
        background-color: #84d6ff;
        transform: translateY(15px);
        // width: calc(100%);
        &__container {
          width: 100vw;
          padding: 0 5vw 0 5vw;
        }

        box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        &-users {
          &-t {
            height: 44px;
            background-color: rgba(0, 0, 0, 0.03);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            border-bottom: 1px solid #1a1a1a0c;
          }
          &-b {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            margin-top: 20px;
            margin-bottom: 20px;
            padding-left: 30px;
            & a:first-child {
              margin-bottom: 20px;
            }
          }
        }
        & ul {
          margin: 0;
          padding: 0;
          padding: 20px 20px 40px 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-self: flex-start;

          & li {
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            list-style: none;
            text-decoration: none;
            padding-top: 15px;

            padding-bottom: 15px;
            border-bottom: 1px solid #1a1a1a0c;
            & a span {
              color: #1a1a1a;
              font-weight: 500;
            }
          }
        }
      }
      &_b {
        width: 42px;
        height: 42px;
        border-radius: 21px;
        cursor: pointer;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & div {
          width: 20px;
          height: 2px;
          margin-top: 1.5px;
          margin-bottom: 1.5px;
          border-radius: 3px;
          background-color: #58c6ff;
        }
      }
    }
    & img {
      height: 50px;
    }
    & ul {
      display: flex;
      list-style: none;

      margin: 0;
      justify-content: flex-end;
      align-items: center;
      & li {
        margin-right: 35px;
      }
      & li a {
        text-decoration: none;
        font-size: 18px;
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 1004px) {
  .layout {
    &-nav {
      display: none;
      &-mobile {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .layout {
      &__footer {
        margin-top: 0;

        &-container {
          padding: 1rem;
          height: auto;
        }

        &-contact {
          flex-wrap: wrap;
          flex-direction: column;
          gap: 2rem;
          margin: auto;
        }

        &-download {
          flex-direction: column;
          margin-top: 30px;
        }

        &-nav {
          flex-direction: column;
          ul {
            margin-bottom: 30px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }


  // @media screen and (max-width: 525px) {
  //   .layout {
  //     &-nav {
  //       & ul {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding: 0;
    &:first-child {
      margin-left: 6rem;
    }
    li {
      list-style: none;
      padding: 0;
      a {
        font-size: 12px !important;
        cursor: pointer;
        color: #000;
        font-weight: 600;
        &:hover {
          color: #00AAFF;
        }
      }
    }
  }

  &__main {
    li {
      a {
        padding-bottom: 10px;
        border-bottom: 3px solid transparent;
        &:hover {
          color: #000;
          border-bottom: 3px solid #00AAFF;
        }
        &.active {
          border-bottom: 3px solid #00AAFF;
        }
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-radius: 40px;
    color: #ffffff;
    border: 0;
    outline: none;
    background-color: #00AAFF;
    cursor: pointer;
    margin-left: 5rem;
  }
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 0 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__sections {
    width: 60%;
    padding: 2rem;

    h3 {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 20px;
      color: #00AAFF;
    }

    ul {
      justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
      padding: 0;
      li {
        margin: 0;
        p {
          margin-left: 1rem;
          font-size: 12px;
        }
      }
    }
  }

  &__rewards {
    width: 40%;
    padding: 2rem;
    h3 {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #000;
    }

    &-slider {
      height: 170px;
      width: 400px;
      margin: 0 auto;
      .slick-slide > div {
        height: 170px;
        border-radius: 20px;
        overflow: hidden;
       
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
       
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
}

.menu-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 20px 0;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      list-style: none;
      padding: 0 !important;
      width: 100%;
      text-align: center;
      a {
        font-size: 18px !important;
        padding: 15px;
        cursor: pointer;
        display: block;
        &:hover {
          color: #00AAFF;
        }
      }
    }
  }

  &__toggle {
    position: absolute;
    top: -57px;
    right: 20px;
    width: 30px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}

