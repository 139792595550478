.loader-top__slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
  border-radius: 5px;
  z-index: 999999999;
}

.loader-top__line {
  position: absolute;
  opacity: 0.4;
  background: #fff;
  width: 150%;
  height: 5px;
  border-radius: 5px;
}

.loader-top__subline {
  position: absolute;
  background: #f2da52;
  border-radius: 5px;
  height: 5px;

  &--inc {
    animation: loader-top__increase 2s infinite;
    border-radius: 5px;
  }
  &--dec {
    animation: loader-top__decrease 2s 0.5s infinite;
    border-radius: 5px;
  }
}

@keyframes loader-top__increase {
  from {
    left: -5%;
    width: 5%;
    border-radius: 5px;
  }
  to {
    left: 130%;
    width: 100%;
    border-radius: 5px;
  }
}
@keyframes loader-top__decrease {
  from {
    left: -80%;
    width: 80%;
    border-radius: 5px;
  }
  to {
    left: 110%;
    width: 10%;
    border-radius: 5px;
  }
}
